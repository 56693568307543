import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Unsere Verantwortung' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        Wir nehmen unsere Verpflichtung gegenüber unseren Nutzern sehr ernst. 
        Wenn Sie unsere Hilfe bei Ihrem Projekt benötigen, Fragen zur Nutzung der Website oder technische Schwierigkeiten haben, 
        zögern Sie bitte nicht, uns uns zu kontaktieren.
      </Text>
    </Card>
  </Section>
)

export default Commitment